import React, { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const LazyLoadLoginView = lazy(() => import('./components/login/login.view'));
const LazyLoadForgotPasswordView = lazy(() => import("./components/forgot.password/forgot.password.view"));
const LazyLoadVerifyCodeView = lazy(() => import("./components/forgot.password/verify.code.view"));
const LazyLoadRegisterView = lazy(() => import("./components/account.signup/account.signup.view"));
const LazyLoadAppCoreView = lazy(() => import('./components/app.core/app.core.view'));
const LazyLoadAppShopperView = lazy(() => import('./components/app.shopper/app.shopper.view'));


const App = () => {

  return (
    <Router future={{ v7_startTransition: true }}>
      <Routes>
      <Route path="/" element={<Navigate to="login" />} />
        <Route path="/login" element={<LazyLoadLoginView />} />
        <Route path='/reset-password' element={<LazyLoadForgotPasswordView />} />
        <Route path='/verify-code' element={<LazyLoadVerifyCodeView />} />
        <Route path='/register' element={<LazyLoadRegisterView />} />
        <Route path="/start/*" element={<LazyLoadAppCoreView />} />
        <Route path="/shopper/*" element={<LazyLoadAppShopperView />} />

      </Routes>
    </Router>
  );
}

export default App;
